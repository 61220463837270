<template>
    <div class="bg-gray-100 w-full h-full securdesktop">
      <header class="bg-white shadow-lg py-4 px-4 sm:px-6 flex items-center justify-between">
        <img class="logo w-28 sm:w-40" :src="require('@/assets/logos/logo_horizontal.png')" />
        <div class="sm:flex items-center space-x-4 sm:space-x-6">
          <span class="text-xs sm:text-sm pt-1 sm:block hidden">
            {{ firstname }} {{ lastname }}
          </span>
          <div class="border-l border-gray-300 h-6 sm:block hidden"></div>

          <LocaleSwitcher v-on:change="$emit('localeChange', $event)" class="text-xs sm:text-sm sm:block hidden" />
          <div class="border-l border-gray-300 h-6 sm:block hidden"></div>

          <div @click="logout()" class="flex items-center cursor-pointer px-3 sm:px-4 py-2 text-gray-700 hover:bg-primary-600 hover:text-white rounded transition">
            <BaseIcon icon="sign-out-alt" class="mr-1 sm:mr-2" />
            <span class="text-xs sm:text-sm">{{ $t("user_menu.log_out") }}</span>
          </div>
        </div>
      </header>

      <div class="w-full min-h-screen p-5 flex flex-col items-center ">
        <!--SecurTrack Map-->
        <div class="map-alarm-container bg-white text-white rounded-lg w-full sm:w-full xl:w-3/5 md:w-full h-auto min-h-0 flex flex-col mb-5">
          <div class="map-container flex-grow justify-center">
            <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" class="google_map_wrapper" ref="map">
              <GmapInfoWindow
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="
                  infoWinOpen = false;
                  selected_alarm_id = '';
                "
              ></GmapInfoWindow>

              <GmapCluster :zoom-on-click="true">
                <GmapMarker v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" @mouseover="toggleInfoWindow(m, index)" @mouseout="toggleInfoWindow(m, index)" :key="index" :icon="{ url: m.icon }"></GmapMarker>
              </GmapCluster>
              <GmapCircle v-for="(pin, index) in alarmCircles" :key="index" :center="pin.position" :visible="true" :options="pin.options"></GmapCircle>
            </GmapMap>
          </div>
          <div class="rounded-lg shadow-md hover:shadow-lg transition p-4 text-center" v-if="!push_btn_larms.length">
            <span>Inga aktuella larm</span>
          </div>
          <div class="rounded-lg shadow-md hover:shadow-lg transition p-4" v-if="push_btn_larms.length">
            <div v-for="(a, index) in push_btn_larms" :key="index" class="pb-4">
              <div @click="focusAlarm(a.imei_number)" class="alarm-box cursor-pointer py-4 px-4 rounded-lg flex justify-between items-start" v-bind:class="{ 'bg-red-500 animate-pulse': a.pushButton && !a.acknowledge, 'bg-green-500': a.acknowledge}">
                <div>
                  <div class="item text-sm font-bold pb-2 text-white">{{ a.unit_name }}</div>
                  <div v-if="customers.length > 1" class="item text-sm pb-2 text-white">{{ getCustomerNameFromUnitId(a.parent_id) }}</div>
                  <div class="item text-sm pb-2 text-white"><b>Verksamhet:</b> {{ getNameFromUnitId(a.parent_id) }}</div>
                  <button class="btn-green" v-if="!a.acknowledge" v-on:click.stop="confirmAcknowledgeAlarmPrompt(a._id, a.unit_name)">Kvittera larm</button>
                </div>
                <div class="text-right">
                  <div class="item text-sm pb-2 text-white"><b>Telefon:</b> {{ a.phone }}</div>
                  <div class="item text-sm pb-2 text-white"><b>Inomhusposition:</b> {{ a.tag && a.tag.unit_name ? a.tag.unit_name : "Ingen data" }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END SecurTrack Map-->

        <!-- SecurDesktop alerts-->
        <div class="alerts-container flex flex-wrap gap-4 w-full xl:w-3/5 h-auto justify-center">
          <div v-for="unit in filteredUnitsWithSecurDesktop" :key="unit._id" class="unit-container bg-white text-white px-4 pt-2 rounded-lg shadow-lg w-full flex flex-col pb-4">

            <div class="unit-header flex justify-between pb-2 pl-1">
              <h3 class="text-gray-500 text-2xl font-semibold font-sans">
                {{ unit.name }} 
              </h3>
            </div>
            <hr>

            <div class="alerts-container pt-4">
              <button 
                v-for="alert in unit.alerts" 
                :key="alert._id" 
                class="btn-secondary-outline py-1 px-3 text-sm cursor-pointer mr-5"
                @click="selectAlert(alert, unit._id)" 
                :class="{ 'bg-yellow-500 text-white border-0': selectedAlerts[unit._id] && selectedAlerts[unit._id]._id === alert._id }">
                {{ alert.unit_name }}
              </button>
            </div>
            

            <div class="alert-container flex gap-4 py-4 w-full" v-if="selectedAlerts[unit._id]">
              <textarea 
                v-model="selectedAlerts[unit._id].securDesktop.alarmMessage"
                class="w-1/2 h-40 bg-gray-100 p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 text-sm"
                placeholder="Text för utlarmning">
              </textarea>

              <textarea 
                v-model="selectedAlerts[unit._id].securDesktop.endMessage"
                class="w-1/2 h-40 bg-gray-100 p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                placeholder="Text för fara över">
              </textarea>

            </div>


            <div class="flex flex-row gap-4 justify-center" v-if="selectedAlerts[unit._id]">
              <button 
                class="bg-red-500 hover:bg-red-600 text-white rounded-lg shadow transition duration-200 text-lg py-2 px-4 flex-1"  
                @click="openSendMessageModal('start', unit._id)" 
                :disabled="!selectedAlerts[unit._id]">
                Larma
              </button>

              <button 
                class="bg-green-500 hover:bg-green-600 text-white rounded-lg shadow transition duration-200 text-lg py-2 px-4 flex-1" 
                @click="openSendMessageModal('end', unit._id)" 
                :disabled="!selectedAlerts[unit._id]">
                Fara över 
              </button>
            </div>
          </div>
        </div>
        <modal name="send-alert-modal" height="auto" :scrollable="true" class="send-modal">
          <div class="p-6">
            <h3 class="text-gray-500 text-2xl font-semibold font-sans mb-4">Bekräfta utskick</h3>
            <hr class="mb-4">
            <div class="mb-4">
              <label class="text-gray-500 text-sm font-semibold font-sans">Meddelande:</label>
              <textarea v-model="sendAlertMessage" class="w-full border border-gray-300 rounded p-2" rows="3"></textarea>
            </div>

            <div class="flex justify-end space-x-4">
              <button @click="$modal.hide('send-alert-modal')" class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400">
                Avbryt
              </button>
              <button @click="confirmSendAlert()" class="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">
                Skicka
              </button>
            </div>
          </div>
        </modal>
      </div>
    </div>
</template>



<script>
import LocaleSwitcher from "@/components/locale_switcher";
import { gmapApi } from "vue2-google-maps";
import "@googlemaps/markerclustererplus";

export default {
  title() {
    return this.$t("SecurDesktop");
  },

  components: {
    LocaleSwitcher,
  },
  
  data() {
    return {
      customer_id: this.$route.params.id,
      user: this.$store.state.user,
      firstname: this.$store.state.user.firstname || "",
      lastname: this.$store.state.user.lastname || "",
      push_btn_larms: [],
      selected_alarm_id: "",
      sendAlertMessage: "",
      extraAlertMessage: "",
      selectedAlerts: {},
      selectedUnitId: null,

      // Map related
      center: { lat: 59.3293, lng: 18.0686 }, // 
      zoom: 10,
      mapFullScreenControl: true,
      markers: [],
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      alarmCircles: [],
      polygonPath: [],
      polygonOptions: [],
      // Fetching unit alarms
      refreshIntervalRef: null,
      refreshInterval: 20000, // every 20s
      securDesktopAlerts: [],
      customer_alarms: [],
      soundOn: false,
      units: []
    };
  },
  methods: {

    google: gmapApi,

    logout() {
      this.$store.dispatch("logout");
      let locale = localStorage.getItem("securcloud_locale") || "sv";
      this.$store.dispatch("setLanguage", locale);
      this.$router.push("/");
    },

    async refreshGPSData() {
      try {
        await this.getGPSCustomer();
      } catch (error) {
        this.handleError("Error when fetching GPS-data:", error);
      }

      this.refreshIntervalRef = setTimeout(() => {
        this.refreshGPSData();
      }, this.refreshInterval);
    },

    getGPSCustomer() {
      var vm = this;

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/gps/customer/securdesktop/${this.customer_id}`)
        .then((response) => {

          this.customers = response.data.customers;
          this.units = response.data.units;
          this.filtered_units = this.units;
          this.gps_alarms = response.data.gps_alarms;
          this.all_unit_alarms = response.data.customer_alarms;
          this.securDesktopAlerts = response.data.securDesktop;
          this.customer_alarms = response.data.customer_alarms;

          // remove alarms that are not in gps db (as they are still not active and registered completely in system)

          var push_btn_larms = [];

          for (let i = 0; i < response.data.customer_alarms.length; i++) {
            let el = response.data.customer_alarms[i];

            el.tracking_active = false;
            el.tracking_id = null;

            let a = _.find(this.gps_alarms, { imei_number: el.imei_number });

            if (a) {
              if (a.gps_pos) {
                el.gps_pos = a.gps_pos; 
              }

              // tag
              el.tagFound = false;
              if (a.tag_id && a.tag_timestamp && new Date().getTime() - new Date(a.tag_timestamp).getTime() < 300000) el.tagFound = true;
 
              // accuracy radius
              el.accuracyRadius = 0;
              if (new RegExp(/^SRT/g).test(el.type)) {
                // satellites, hdop
                el.satellites = a.satellites;
                el.hdop = a.hdop;
                // accuracy radius
                let userRangeError = 70;
                if (el.satellites >= 5) userRangeError = 30;

                if (el.hdop >= 0) el.accuracyRadius = Math.ceil(el.hdop * userRangeError);
              } else if (new RegExp(/^TWIG/g).test(el.type)) el.accuracyRadius = a.hdop;
              // twig devices

              el.pushButton = false;
              if (a.lastPushButtonTimestamp && new Date().getTime() - a.lastPushButtonTimestamp < 1800000) {
                el.pushButton = true;
                el.lastPushButtonTimestamp = a.lastPushButtonTimestamp;
                push_btn_larms.push(el);


                let pushButtonTime = new Date(el.lastPushButtonTimestamp);
                let acknowledgeTime = new Date(a.lastAcknowledgeTimeStamp);

                el.acknowledge = false;
                  if (acknowledgeTime) {
                    if (acknowledgeTime > pushButtonTime) {
                      el.acknowledge = true;
                      
                      const currentTime = new Date();
                      const diffInMinutes = (currentTime - acknowledgeTime) / (1000 * 60);
                      // If acknowledge timestamp is more than 30 mins old, set to false and alarm will transfer to normal alarm list.
                      if (diffInMinutes > 30) {
                        el.acknowledge = false;
                        el.pushButton = false;
                        this.removeAlarmCircle(el.imei_number);
                      }
                    } 
                  }
                  // If pushbutton is true and alarm is not in test mode or acknowledged play sound.
                  // soundOn boolean can be toggled by user and decides if sound should play or not.
                  
                  if (el.pushButton && !el.acknowledge) {
                    if (!this.soundOn && !this.alarmAudio) { 
                      this.playAlarmSound();
                    }
                  } else {
                    this.stopAlarmSound();
                  }
              } 

              // populate indoor alarms
              if (a.tag_id && a.tag_timestamp && new Date().getTime() - new Date(a.tag_timestamp).getTime() < 1800000) {
                let tagg = _.find(this.all_unit_alarms, function(o) {
                  if (/^SRT/.test(el.type)) return o.modelnumber == a.tag_id.slice(2);
                  else if (/^TWIG/.test(el.type)) return o.modelnumber == a.tag_id;
                  else return false;
                });
                el.tag = tagg
              }
            }
          }
      
          push_btn_larms = push_btn_larms.sort(function(a, b) {
            return b.lastPushButtonTimestamp - a.lastPushButtonTimestamp;
          });

          this.push_btn_larms = [...(push_btn_larms || [])];
          this.alarmCircles = [];
          this.markers = [];


          this.push_btn_larms.forEach((el) => {
            let alarm_index = _.findIndex(this.push_btn_larms, { imei_number: el.imei_number });

            if (alarm_index >= 0 && this.push_btn_larms[alarm_index].type != "SRT326" && el.gps_pos && el.gps_pos.lat != null && el.pushButton) {
              let larm = this.push_btn_larms[alarm_index];
              // Set icons for alarms in securTrack
              // base version of icon is SRT405/i

              let icon = require("@/assets/icon/new_small_map_marker.png");
              if (larm.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
              else if (larm.type == "SRT341") icon = require("@/assets/icon/srt341.png");
              else if (larm.type == "SRT406") icon = require("@/assets/icon/small_SRT406.png");
              else if (larm.type == "SRT406i") icon = require("@/assets/icon/small_SRT406.png");
              else if (new RegExp(/^TELTONIKA/g).test(larm.type)) icon = require("@/assets/icon/car.png");
              else if (/^TWIG/.test(larm.type)) {
                icon = require("@/assets/icon/twig_map_marker.png");

                if (larm.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
                else if (/^TWIG One/.test(larm.type)) icon = require("@/assets/icon/twig_one.png");
              }
              else if (/^EMERIT/.test(larm.type)) {
                icon = require("@/assets/icon/emerit_watch.png");

                if (larm.type == "EMERIT e-B10") icon = require("@/assets/icon/smartphone.png");
              }

              let markerObj = {
                icon: icon,
                position: { lat: el.gps_pos.lat ? el.gps_pos.lat : 0, lng: el.gps_pos.lng ? el.gps_pos.lng : 0 },
                infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${larm.unit_name}</b><br>
                        Lat: <b>${el.gps_pos.lat ? el.gps_pos.lat.toFixed(3) : "-"}</b>, Lng: <b>${el.gps_pos.lng ? el.gps_pos.lng.toFixed(3) : "-"}</b>
                        `,
                alarmId: larm._id,
                imei: larm.imei_number,
              };

              this.markers.push(markerObj);

              // alarm trigger circle
              if (el.lastPushButtonTimestamp && new Date().getTime() - el.lastPushButtonTimestamp < 1800000) {
                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: el.imei_number,
                  options: {
                    fillColor: "#FF0000",
                    fillOpacity: 0.5,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    radius: 50,
                  },
                });

                if (larm.accuracyRadius > 0 && larm.type != "SRT326") {
                // accuracy circle
                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: larm.imei_number,
                  options: {
                    fillColor: "#ffce73",
                    fillOpacity: 0.2,
                    // strokeColor: '#ffc250',
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    radius: larm.accuracyRadius,
                  },
                });
              }
            }
          }});

          if (this.firstLoad) {
            if (this.selected_alarm_id) {
              this.$nextTick().then(() => {
                let a = _.find(this.push_btn_larms, { _id: vm.selected_alarm_id });
                if (a) vm.focusAlarm(a.imei_number);
              });
            } else {
              this.generateBounds();
            }

            this.firstLoad = false;
          }

          if (document.hasFocus()) this.handleSuccess("Plats uppdaterad", "top-right", 1000);
        })
        .catch((error) => {
          this.handleError(error, "Ett problem uppstod när plats uppdaterades");
        });
    },

    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
        if (!this.infoWinOpen) this.selected_alarm_id = "";
        else this.selected_alarm_id = marker.alarmId;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
        this.selected_alarm_id = marker.alarmId;
      }
    },

    getNameFromUnitId(id) {
      let u = _.find(this.units, { unit_id: id });
      if (u) return u.name;
      else return "";
    },

    focusAlarm(imei) {
      var vm = this;

      let markerIndex = _.findIndex(this.markers, { imei: imei });

      if (markerIndex >= 0) {
        setTimeout(() => {
          vm.$refs.map.$mapObject.panTo(vm.markers[markerIndex].position);
          vm.$refs.map.$mapObject.setZoom(18);
        }, 300);

        this.$nextTick().then(() => {
          vm.toggleInfoWindow(this.markers[markerIndex], markerIndex);
        });
      } else {
        let alarm = _.find(this.customer_alarms, { imei_number: imei });
        let gps = _.find(this.gps_alarms, { imei_number: alarm.imei_number });
        this.showSRT326({
          alarm_name: alarm.unit_name,
          battery: gps.battery_percent ? `${gps.battery_percent} %` : "-",
          gsm_signal: gps.gsm_signal ? this.getGSMSignalPercent(gps.gsm_signal) : "-",
        });
      }

      if (this.isMobile) this.mobileMenuActive = false;
    },

    playAlarmSound() {
      try {
        setTimeout(() => {
          if (!this.alarmAudio) {
            this.alarmAudio = new Audio(require('@/assets/audios/emergency-alarm.mp3'));
            this.alarmAudio.loop = true;
          }
          this.alarmAudio.play().catch(error => this.handleError("Error when playing sound: ", error));
        }, 500); // Wait 500ms before playback to counter the autoplay obstacle in browsers
      } catch (error) {
        this.handleError("Error when playing sound: ", error);
      }
    },

    stopAlarmSound() {
      if (this.alarmAudio) {
        this.alarmAudio.pause();
        this.alarmAudio.currentTime = 0; 
        this.alarmAudio = null;
      }
    },

    confirmAcknowledgeAlarmPrompt(id ,name) {
      this.$modal.show("dialog", {
        title: `Kvittens av larm`,
        text: `<p style="text-align: center;">Kvittera inkommet larm från ${name}?<br><br> <br>
          När larmet är kvitterat ligger det kvar aktivt i 30 minuter och ljudet tystas.</p> `,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">NEJ</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">JA</div>',
            handler: () => {
              // Call function to store confirm in DB. 
              this.confirmAcknowledgeAlarm(id, name);
            },
          },
        ],
      });
    },

    async confirmAcknowledgeAlarm(id, name) {
      let alarmId = id;

      try {
        /*
        Api call to store acknowledge timestamp in GPS collection. If success it stops the alarm
        and fetch alarm center alarms again to refresh data.
        */
        let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm/acknowledge-alarm/${alarmId}`);
        this.$modal.hide("dialog");

        // if response is false, the alarm has already been acknowledged. Shows message is toaster.
        if (response.data.success) {
          this.handleSuccess(`Larm: ${name} kvitterat`);
          this.stopAlarmSound();
          this.getGPSCustomer();
          let alarm = this.push_btn_larms.find(a => a._id === alarmId);
          if (alarm) {
            this.$set(alarm, 'acknowledge', true);
          }
        }
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    selectAlert(alert, unitId) {
      this.$set(this.selectedAlerts, unitId, alert); 
    },
    
    openSendMessageModal(messageType, unitId) {
      this.selectedUnitId = unitId;
      let selectedAlert = this.selectedAlerts[this.selectedUnitId];

      if (!selectedAlert) {
        return;
      }

      this.sendAlertMessage = messageType === "start"
        ? selectedAlert.securDesktop.alarmMessage
        : selectedAlert.securDesktop.endMessage;

      this.$modal.show("dialog", {
        title: "Bekräfta utskick",
        text: `
          <label class="text-gray-500 text-sm font-semibold font-sans">Meddelande:</label>
          <textarea id="send-alert-input" class="w-full border border-gray-300 rounded p-2 mt-2"
            rows="3">${this.sendAlertMessage}</textarea>
        `,
        buttons: [
          {
            title: '<div class="bg-gray-300 text-gray-700 text-sm font-sans py-2 rounded">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-600 text-white text-sm font-sans py-2 rounded">Skicka</div>',
            handler: () => {
              let message = document.getElementById("send-alert-input").value;
              this.confirmSendAlert(message, unitId);
            },
          },
        ],
      });
    },


    confirmSendAlert(message, unitId) {
      if (!message.trim()) {
        console.warn("Meddelandet får inte vara tomt!");
        return;
      }

      let selectedAlert = this.selectedAlerts[unitId];
      let contacts = selectedAlert.securDesktop.contactPersons.map(person => person.phone);

      this.sendAlert(message, contacts)
        .then(() => {
          this.$modal.hide("dialog");
        })
        .catch(error => {
          console.error("Fel vid skickning av meddelande:", error);
        });
    },



    async sendAlert(finalMessage, contacts) {
      if (contacts.length === 0) {
        console.warn("Ingen kontaktperson angiven!");
        return Promise.reject("Alert has no contacts");
      }

      try {
        let formattedContacts = contacts.map(phone => ({ msisdn: phone }));
        let response = await this.axios.post(
          `${process.env.VUE_APP_SERVER_URL}/alarm/securdesktop-alert`,
          { message: finalMessage, contacts: formattedContacts }
        );

        console.log("Larm skickat:", response.data);
        this.handleSuccess("Larm utskickat", "top-right", 1000);
        return Promise.resolve(); 
      } catch (error) {
        console.error("Fel vid skickning av meddelande:", error);
        return Promise.reject(error); 
      }
    }

  },

  created() {
    this.getGPSCustomer();
    this.setPageTitle(`SecurDesktop`);
  },
  computed: {
    filteredUnitsWithSecurDesktop() {
      let unitsWithAlert = this.units.filter(unit => unit.addons && unit.addons.includes('securdesktop') && this.securDesktopAlerts.some(alert => alert.parent_id === unit.unit_id)
        ).map(unit => ({...unit, alerts: this.securDesktopAlerts.filter(alert => alert.parent_id === unit.unit_id)}));
      return unitsWithAlert;
    }

  },
  mounted() {
    this.refreshGPSData();
  },
  beforeDestroy() {
    if (this.refreshIntervalRef) {
      clearTimeout(this.refreshIntervalRef);
    }
  }

};
</script>
